import clsx from "clsx";
import { useState } from "react";

import { UserData } from "../../../types";

import classes from "./style.module.scss";

export interface ChatListPersonItemProps {
  data: UserData;
}

function fullNameOf(user: UserData) {
  const { first, last } = user.name;
  return [first, last].join(' ');
}

export default function ChatListPersonItem({ data: user }: ChatListPersonItemProps) {
  const [messageText, setMessageText] = useState("");

  return (
    <div className={classes.ChatListPersonItem}>
      <img
        src={user.picture.large}
        alt={fullNameOf(user)}
        className={classes.ChatListPersonItem__avatar}
      />
      <div className={classes.ChatListPersonItem__title}>
        {fullNameOf(user)}
      </div>
      <div className={classes.ChatListPersonItem__inlineChat}>
        <div className={classes.ChatListPersonItem__messages}>
          <div className={classes.ChatListPersonItem__message}>
            hi
          </div>
          <div className={classes.ChatListPersonItem__message}>
            I have a question...
          </div>
        </div>
        <textarea
          placeholder="Send a message"
          value={messageText}
          onChange={(event) => setMessageText(event.target.value)}
          className={classes.ChatListPersonItem__messageInput}
        />
        <button className={clsx(
          classes.ChatListPersonItem__button,
          classes.ChatListPersonItem__messageSubmit,
          messageText && classes.ChatListPersonItem__messageSubmit_visible,
        )}>
          Send
        </button>
        <button className={classes.ChatListPersonItem__button}>
          View chat &raquo;
        </button>
      </div>
    </div>
  );
}
