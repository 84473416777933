import classes from "./style.module.scss";

export interface ChatListProjectItemProps {
  name: string;
}

export default function ChatListProjectItem({ name }: ChatListProjectItemProps) {
  return (
    <div className={classes.ChatListProjectItem}>
      <div className={classes.ChatListProjectItem__issueList}>
        <div className={classes.ChatListProjectItem__issue}>
          <div className={classes.ChatListProjectItem__issueTitle}>
            SQL injection possible when adding todo
          </div>
          <div className={classes.ChatListProjectItem__issueLink}>
            todo/123
          </div>
        </div>
        <div className={classes.ChatListProjectItem__issue}>
          <div className={classes.ChatListProjectItem__issueTitle}>
            SQL injection possible when adding todo
          </div>
          <div className={classes.ChatListProjectItem__issueLink}>
            todo/123
          </div>
        </div>
        <div className={classes.ChatListProjectItem__issue}>
          <div className={classes.ChatListProjectItem__issueTitle}>
            SQL injection possible when adding todo
          </div>
          <div className={classes.ChatListProjectItem__issueLink}>
            todo/123
          </div>
        </div>
      </div>
      <img
        src="/logo192.png"
        alt={name}
        className={classes.ChatListProjectItem__icon}
      />
      <div className={classes.ChatListProjectItem__title}>
        {name}
      </div>
      <div className={classes.ChatListProjectItem__controls}>
        <button className={classes.ChatListProjectItem__button}>
          View chat &raquo;
        </button>
      </div>
    </div>
  );
}