import { useState } from "react";
import { FetchUsersData } from "../../../types";
import ChatListPersonItem from "../../widgets/ChatListPersonItem";
import ChatListProjectItem from "../../widgets/ChatListProjectItem";

import classes from "./style.module.scss";

async function fetchUsers(quantity = 50): Promise<FetchUsersData> {
  const response = await fetch(`https://randomuser.me/api/?results=${quantity}&nat=gb`);
  const data = await response.json();

  return data;
}

const testDataPromise = fetchUsers(2);

export default function Main() {
  const [testData, setData] = useState<FetchUsersData>();
  testDataPromise.then(setData);

  return (
    <div className={classes.grid}>
      <div className={classes.sidebar}>
        <div className={classes.chats}>
          <div className={classes.chatList}>
            <div className={classes.chatListTitle}>
              Projects
            </div>
            <ChatListProjectItem name="VidShare" />
            <ChatListProjectItem name="To Do" />
            <ChatListProjectItem name="Durrant.tech" />
          </div>
          <div className={classes.chatList}>
            <div className={classes.chatListTitle}>
              People
            </div>
            {testData?.results.map((user) => (
              <ChatListPersonItem data={user} />
            )) || "Loading..."}
          </div>
        </div>
      </div>
      <div className={classes.currentChatHeading}>
        <img
          src="/logo192.png"
          alt="To Do"
          className={classes.currentChatHeadingImage}
        />
        <div className={classes.currentChatHeadingTitle}>
          To Do
        </div>
      </div>
      <div className={classes.main}>
        <div className={classes.currentChat}>
          <div className={classes.messageThread}>
            <div className={classes.messageAuthor}>
              Dianne Cecilia
            </div>
            <div className={classes.messageText}>
              Morning
            </div>
          </div>
          <div className={classes.messageThread}>
            <div className={classes.messageAuthor}>
              Darnell Byrd
            </div>
            <div className={classes.messageText}>
              I hope there aren't too many bugs to get through today.
            </div>
            <div className={classes.messageText}>
              I've got quite enough work on my plate already.
            </div>
          </div>
          <div className={classes.messageThread}>
            <div className={classes.messageAuthor}>
              Dianne Cecilia
            </div>
            <div className={classes.messageText}>
              Speaking of bugs...
            </div>
            <div className={classes.issue}>
              <div className={classes.issueTitle}>
                SQL injection possible when adding todo
              </div>
              <div className={classes.issueText}>
                <ol>
                  <li>Create todo</li>
                  <li>Type ('; delete all databases;)</li>
                  <li>Save</li>
                </ol>
              </div>
              <input
                placeholder="Add a comment"
                className={classes.issueCommentInput}
              />
              <div className={classes.issueLink}>todo/123</div>
            </div>
          </div>
          <div className={classes.messageThread}>
          <div className={classes.messageAuthor}>
              Darnell Byrd
            </div>
            <div className={classes.messageText}>
              You've got to be kidding me...
            </div>
          </div>
        </div>
      </div>
      <div className={classes.toolbar}>
      </div>
      <div className={classes.inputBar}>
        <input
          placeholder="Send a message"
          className={classes.messageInput}
        />
      </div>
    </div>
  );
}
